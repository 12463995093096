<template>
  <div class="main-body">
   <div id="main-content-list">
    <button onclick="moreContent()">More Content</button>

    <div v-for="(review,i) in contentList" :key="review" :id="'content-blck-'+review['main'][0].ID" class="content-blck" :style="'background-color:'+postBg[i]">
      <div :id="'content-title-'+review['main'][0].ID">
          <div class="review-title" :id="'title-'+review['main'][0].ID">
              {{review['main'][0].post_title}}
          </div>
          
      </div>

      <div v-html="review['main'][0].videoCode" v-if="review['main'][0].videoCode" class="video-trailer"></div>   
  

      <div class="summary-blck" :id="'summary-blck-'+review['main'][0].ID">
          <div v-if="review['main'][0].summary1" class="summary-txt" :id="'summary-txt-'+review['main'][0].ID+'-1'">
             {{review['main'][0].summary1}} 
          </div>
          <div v-if="review['main'][0].summary2" class="summary-txt" :id="'summary-txt-'+review['main'][0].ID+'-2'">
             {{review['main'][0].summary2}} 
          </div>
          <div v-if="review['main'][0].summary3" class="summary-txt" :id="'summary-txt-'+review['main'][0].ID+'-3'">
             {{review['main'][0].summary3}} 
          </div>
          <div v-if="review['main'][0].summary4" class="summary-txt" :id="'summary-txt-'+review['main'][0].ID+'-4'">
             {{review['main'][0].summary4}} 
          </div>
          <div v-if="review['main'][0].summary5" class="summary-txt" :id="'summary-txt-'+review['main'][0].ID+'-5'">
             {{review['main'][0].summary5}} 
          </div>
       </div>   
       <div :id="'content-txt-'+review['main'][0].ID">

           <div class="blck-main-pic" v-if="review['main'][0].main_pic"><img :width="review['main'][0].picwidth" :height="review['main'][0].picheight" :src="'../images_items/'+review['main'][0].main_pic"/></div>

           <div v-html="review['main'][0].post_content"  class="review-content" :id="'review-'+review['main'][0].ID"></div>
       </div>        
       <div class="review-credentials">
         <div class="author-blck">
           <div class="byauthor">
             by @{{review['main'][0].nick}}
           </div> 
           <div>
             

              <apexchart v-if="review['main'][0].TR<0" :options="TRdonutOptions" :series="[0]"></apexchart>
              <apexchart v-else-if="review['main'][0].TR==''" :options="TRdonutOptions" :series="[0]"></apexchart>
              <apexchart v-else-if="review['main'][0].TR>100" :options="TRdonutOptions" :series="[100]"></apexchart>
              <apexchart v-else :options="TRdonutOptions" :series="[review['main'][0].TR]"></apexchart>

           </div>   
         </div>
         <div class="voting-blck">
              <div class="like-blck">
                <img class="like-img" :id="'like-'+review['main'][0].ID" alt="LIKE" title="LIKE" src="https://www.reviewstream.com/images/like-vue.png"/>
              </div>
              <div class="like-blck">
                <img class="like-img" :id="'dislike-'+review['main'][0].ID" alt="DISLIKE" title="DISLIKE" src="https://www.reviewstream.com/images/dislike-vue.png"/>
              </div>
              
         </div>    
       </div>


       <div class="comments-blck-title" :id="'comments-blck-'+review['main'][0].ID"><img class="chat-icon" src="https://www.reviewstream.com/reviews/images/chat-icon.png?v=1"/>Questions, Answers, Comments</div>
   
       <div v-for="(comment,i) in review['comments']" :key="i" class="commentsBlck" :id="'commentBlck-'+review['main'][0].ID">

           <div :class="isClusterStart(comment.relations)" :id="'commentsClusterBlck-'+comment.relations">
            <div v-html="commentBy(comment.comment_by, comment.comment_author)" class="comment-author-blck"></div>  
            <div v-html="postComment(comment.comment_content)" ></div>
          </div>    
       
        </div>


      
        <textarea name="comment-input" class="comment-input" :id="'comment-input-'+review['main'][0].ID"></textarea>  
        <button class="comment-input-btn" :id="'comment-input-btn-'+review['main'][0].ID" v-on:click="submitComment(review['main'][0].ID)">submit comment</button>

        
        <div class="content-blck-bottom"></div>
 
    </div>







   </div>





   <div class="related-column">
       <ul class="related-blck-permanent">
        <li v-for="related in relatedContentRightColumn"  v-bind:key="related">
          <a :href="related.related_link" :title="related.related_title">
          <div class="right-rel-title">
            {{ related.related_title }}
          </div>
          <div><img :src="'https://www.reviewstream.com/images_items/'+related.related_pic" :width="(related.pic_width*0.6)" :height="(related.pic_height*0.6)"/></div>
          <div class="right-summary-blck">
           <div v-if="related.summary1" class="right-summary">&bull; {{ related.summary1 }}</div>
           <div v-if="related.summary2" class="right-summary">&bull; {{ related.summary2 }}</div>
           <div v-if="related.summary3" class="right-summary">&bull; {{ related.summary3 }}</div>
           <div v-if="related.summary4" class="right-summary">&bull; {{ related.summary4 }}</div>
           <div v-if="related.summary5" class="right-summary">&bull; {{ related.summary5 }}</div>
           <div class="right-summary-out-icon"><vue-feather type="arrow-right-circle" size="2.5rem" stroke-width="1"></vue-feather></div>
           </div>
         </a>
        </li>
      </ul>
   </div>

  </div>

  <!---<Top/>--->

 
</template>

<script>
  import { ref, onBeforeMount, onUnmounted, onMounted } from 'vue'
  import {unescape} from 'html-escaper'
  import VueFeather from 'vue-feather'
  import VueApexCharts from "vue3-apexcharts"

  //import ContentBlock from './components/ContentBlock.vue'
  //import Top from './components/Top.vue'


function shuffleBackgroundColor(bgcolors){
      let newbgcolors = [...bgcolors];
      const getRandomValue = (i, N) => Math.floor(Math.random() * (N - i) + i);
      newbgcolors.forEach( (elem, i, arr, j = getRandomValue(i, arr.length)) => [arr[i], arr[j]] = [arr[j], arr[i]] );
      return newbgcolors;
}




  export default {
    components: {
      //InlineSvg
      VueFeather,
      apexchart: VueApexCharts
      //Top,
      //ContentBlock
    },
    data: function() {
      return {
       TRdonutOptions: {
          chart: {
            type: 'radialBar',
          },

          labels: ['TRUST'],
 
          colors: [function({ value, seriesIndex, w }) {
             if (value < 25) {
               return '#ff0000'
             } else if (value <50){
               return '#FAAB19'
             } else if (value <75){
               return '#320BF5'
             } else {
               return '#06C72B'
             }
          }],
    responsive: [{
    breakpoint: 480,
    options: {
      plotOptions: {
        radialBar: {
              hollow: {
                margin: 0,
                size: "50%"
              },
              dataLabels: {
                name: {
                 offsetY: -2,
                 show: true,
                 fontSize: "calc(4px + 0.6vw + 0.6vh)"
                },
                value: {
                 offestY: -20,
                 show: true,
                 fontSize: "calc(4px + 0.8vw + 0.8vh)"
                }
               }
          }
       }
     }
    }],
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 270,
              hollow: {
                margin: 0,
                size: "50%"
              },
              dataLabels: {
                name: {
                 offsetY: -2,
                 show: true,
                 color: "#444",
                 fontSize: "calc(4px + 0.4vw + 0.4vh)"
                },
                value: {
                 offsetY: -3,
                 color: "#444",
                 fontSize: "calc(4px + 0.5vw + 0.5vh)",
                 show: true,
                  formatter: function (val) {
                    return val
                  }
                }

              }
            }
          },
          stroke: {
            lineCap: "round",
          }
        
       }
      }
    },
    setup() {
      let urlObject = new URL(document.location.href);
      let params = urlObject.searchParams;
      let reviewId = params.get("p");

      let content = ref([])
      let content_shown = ref([])
      let contentList = ref([])
      //let comments = ref([])
      //content_shown.value.push(reviewId)
      //let loaded = ref(false)
      let relatedContent = ref([])
      let relatedContentRightColumn = ref([])
      let relatedShown = 0
      let mainCategory = 0
      let categoryShown = 0
      let isCategory = false
      let totalShown = 1
      let postBg = ref([])

      let contentBackgroundColor = ['#fbe3e8','#fceed1','#e5eaf5','#DCC7AA','#e4decd','#f7f7f7','#e1e8f0','#f1f0ee','#CAE4DB','#DFDCE3','#dfd8c8']
      let lastBgColor
      
            
      //let options = {}
      //let series = [44, 55, 41, 17, 15]

      //let googletag = window.googletag || {cmd: []}; 

     
      //var googletag = googletag || {};
      //googletag.cmd = googletag.cmd || [];
 
       let googleAdTag = document.createElement('script')
       let googleAdTagHtml = "window.googletag = window.googletag || {cmd: []};"
       googleAdTagHtml += "googletag.cmd.push(function() {"
       googleAdTagHtml += "googletag.defineSlot('/6355419/Travel/Europe/France/Paris', [300, 250], 'banner-ad').setTargeting('test','infinitescroll').addService(googletag.pubads());"

       googleAdTagHtml += "googletag.pubads().enableSingleRequest();"
       googleAdTagHtml += "googletag.enableServices();"
       googleAdTagHtml += "});"
       googleAdTagHtml += "function moreContent() {"
       googleAdTagHtml += "console.log('clicked');"
       googleAdTagHtml += "googletag.cmd.push(function() {"
       googleAdTagHtml += "var slot = googletag.defineSlot('/6355419/Travel', [300,250])"
       googleAdTagHtml += ".setTargeting('test', 'infinitescroll')"
       googleAdTagHtml += ".addService(googletag.pubads());"
       googleAdTagHtml += "var div = document.createElement('div');div.id = slot.getSlotElementId();"
       googleAdTagHtml += "console.log('div.id '+div.id);"
       googleAdTagHtml += "let adPlaceId = document.getElementsByClassName('banner-ad-more')[0].id;"
       googleAdTagHtml += "let adPlace = document.getElementById(adPlaceId);"

       //googleAdTagHtml += "console.log('tep '+adPlaceId);"
       googleAdTagHtml += "adPlace.setAttribute('class','banner-ad-more-active');"
       //googleAdTagHtml += "document.body.appendChild(div);"
       googleAdTagHtml += "adPlace.append(div);"
       googleAdTagHtml += "console.log('slot '+JSON.stringify(slot));"
       googleAdTagHtml += "googletag.display(slot);});}"
       googleAdTag.innerHTML = googleAdTagHtml
       
       document.head.appendChild(googleAdTag)



     

      
      

     








      let handleScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight/5) {
         // loaded = ref(false)
         if(!content_shown.value.includes(reviewId)){
          //console.log('### '+reviewId)
          fetchContent(reviewId,mainCategory)
          //if(reviewId>0){
          // content_shown.value.push(reviewId)
          //}
          //moreContent()
         } 
         else{
             console.log('stopped '+reviewId)
         }
        
        }
      }
      const fetchContent = async (r,c) => {
        try {
            //console.log(relatedShown+'...'+JSON.stringify(relatedContent.value))
          //console.log('r '+r)  
          let mainResponse
          if(r>0){

           mainResponse = await fetch('https://www.reviewstream.com/reviews/api/review.php?do='+r)
           //console.log('GET REVIEW')
          }
          else{
           mainResponse = await fetch('https://www.reviewstream.com/reviews/api/review.php?cat='+c+'&show='+categoryShown)
           isCategory = true
           console.log('GET CATEGORY categoryShown '+categoryShown)
          }
          const parsedResponse = await mainResponse.json()

          //console.log('parsedResponse '+JSON.stringify(parsedResponse))

          if(JSON.stringify(parsedResponse) != 1){
          content.value = parsedResponse
          if(mainCategory == 0){
              mainCategory = content.value.main[0].post_category
              //console.log('mainCategory '+mainCategory)
          }

          
    //console.log('tut '+relatedContent.value.length);  
          if(relatedContent.value.length==0 || relatedContent.value.length==relatedShown){

              //var a = content.value.related_reviews
              //var b = relatedContent.value
              //console.log(Object.values(a))

              
              
              relatedContent.value = Object.values(content.value.related_reviews).filter(function(objFromA) {
                       return !content_shown.value.find(function(objFromB) {
                       return objFromA.related_id === objFromB
                       })
                      })
              
              relatedContentRightColumn.value = [...relatedContentRightColumn.value,...relatedContent.value]

//console.log('C '+JSON.stringify(relatedContent.value))

//console.log('CC '+JSON.stringify(relatedContentRightColumn.value))



              if(relatedContent.value.length == 0 && mainCategory > 0){
                reviewId = 0
             console.log('CATEGORY STARTED '+mainCategory)

                fetchContent(reviewId, mainCategory)




              }
              else{
              relatedShown=0
                //console.log(relatedShown+'--- '+JSON.stringify(relatedContent.value))
            
              reviewId = relatedContent.value[0].related_id
              //console.log('*** '+reviewId)
              }




          }
          else{
              reviewId = relatedContent.value[relatedShown].related_id
              //console.log('tut '+relatedShown+'...'+reviewId)
          }
          
          if(content.value.main[0].ID>0 && !content_shown.value.includes(content.value.main[0].ID)){

          
          contentList.value = [...contentList.value,content.value]

         //post background color routine
         const getRandomValue = (i, N) => Math.floor(Math.random() * (N - i) + i);
         contentBackgroundColor.forEach( (elem, i, arr, j = getRandomValue(i, arr.length)) => [arr[i], arr[j]] = [arr[j], arr[i]] );
         lastBgColor = contentBackgroundColor[contentBackgroundColor.length-1]
         if(lastBgColor != contentBackgroundColor[0]){
            postBg.value = [...postBg.value,...contentBackgroundColor]
         }   
         //end background color routine

           content_shown.value.push(content.value.main[0].ID)
           totalShown++
           
          // if(isCategory){categoryShown++}

           console.log('TOTAL '+totalShown+'...cat '+categoryShown)


          }
           relatedShown++
           if(isCategory){categoryShown++}

           //console.log('relatedShown '+relatedShown+' reviewId '+reviewId)   
          
           //console.log('reviewId '+reviewId) 
         }     

         //const shuffledColors = shuffleBackgroundColor(contentBackgroundColor)
         //postBg.value = shuffledColors[0]
         //console.log('postBg '+postBg.value)
  
        } 
        catch(err) {
          console.log(err) // state-of-the-art-error-handling
        }
        
        
      }

      onBeforeMount(() => {
        fetchContent(reviewId,mainCategory)
      })
      onMounted(() => {
       // if(window.innerWidth >420){
             // let elMainBody = document.querySelector('.main-body')
             // elMainBody.setAttribute("style","display:flex;")

             // let elMainList = document.querySelector('#main-content-list')
             // elMainList.setAttribute("style","width:75%;")

             // let elRelatedColumn = document.querySelector('.related-column')
             // elRelatedColumn.setAttribute("style","display:block;")

        //}
  
    //Google ads 
       let adScript = document.createElement('script')
       adScript.setAttribute('src', 'https://securepubads.g.doubleclick.net/tag/js/gpt.js')
       document.head.appendChild(adScript)
 
 
 
       //let googleTagAdMore = document.createElement('script')
       //googleTagAdMore.innerHTML = "function moreGoogleAds() {googletag.cmd.push(function() {var slot = googletag.defineSlot('/6355419/Travel', [728, 90]).setTargeting('test', 'infinitescroll').addService(googletag.pubads());var div = document.createElement('div');div.id = slot.getSlotElementId();document.body.appendChild(div);googletag.display(slot);}); }" 

    //end Google ads

       window.addEventListener('scroll', handleScroll)
        
       
      })
      onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll)
      })
      return {
        relatedContent,
        relatedContentRightColumn,
        contentList,
        postBg
      }
    },
    methods: {

      isClusterStart: (relations) => {
        if (relations.match(/:/)) {
          return "commentsClusterBlck related-comment"
        }
      
       return "commentsClusterBlck"
      },
      postComment: (txt) => {
          return unescape(txt.replace(/\r?\n|\r/g, '<br>'))
      },
      commentBy: (c,a) => {
        let by
        if(!c){by = a+' says'}
        else if(!a){by = 'quick note by anonymous'}
        else if(c == 'Q'){by = a}
        else if(c == 'AA'){by = '<span class="author-comment-sign">author</span> '+a+' adds'}
        else{by = '<span class="author-comment-sign">author</span> '+a+' replies'}
        return by
      },
      submitComment: (id) =>{
            const commentUrl = "questions_answers.php";
            const commentTxt = document.getElementById('comment-input-'+id).value.trim();
            const commentData = {id:id, commentTxt:commentTxt}
      
        console.log('submit comment '+id+'...commentTxt: '+commentTxt+'...'+commentUrl);
      
           if(!commentTxt){return;}
           else{
            this.$http.post(commentUrl, commentData, function (data, status, request) {

            // set data on vm
               this.response = data;

            }).error(function (data, status, request) {
                // handle error
            });
           }

      }




   }
  }
</script>




<style>
body{
  margin:0;
  padding:0;
  background-color: #FDF8F5;
}
ul{list-style-type: none;}
#app {
  width:100%;
  margin:0;
  padding:0;
}
#main-content-list{
   position:relative;
   width:100%;
   top:37px;
   
}
.related-column{display:none;}
.content-blck{
  position:relative;
  padding: calc(2px + 3vw + 3vh) calc(2px + 1vw + 1vh) calc(2px + 6vw + 6vh) calc(2px + 1vw + 1vh);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  }
 .content-blck-bottom{
    position: absolute;
    height: calc(10px + 6vw + 6vh);
    width: 100%;
    left: 0;
    right: 0;
    bottom: calc(-5px - 3vw - 3vh);
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    background-color: #FDF8F5;
 } 
 .imgsReview, .rschReview {
    width: 100%;
    margin: 0;
    padding: 0;
}
#imgsFoot, #imgsIntro {
    width: 100%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
}
#imgsReview_comm, #imgsReview_img, #rschReview_img {
    max-width: 600px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}
.review-title{font-size:3em;}
.review-content{font-size: 1.5em;text-align: justify;}
.blck-main-pic{
    float:left;
    width:100%;
    min-width:300px;
    max-width:450px;
    height:auto;
    min-height:300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin-right: 1%;
    margin-bottom:1%;
    border-radius: 5px;
}
.commentsClusterBlck{
    margin-top: 10px;
    font-size: 1.3em;
    border: 1px solid #333;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
}
#comment_quote{
    display: inline-block;
    margin: 0 0 20px 15px;
    font-size: 0.9em;
    color: #7a7a7a;
}
.related-comment{margin-left:5%;}
.summary-txt{
    font-size: 1.5em;
    padding: 2px 5px 2px 10px;
    margin: 0 0 5px 10px;
    border-left: 1px solid #E15611;
    border-bottom: 1px solid #E15611;
    border-radius: 14px;
    background-color:#ffffff;
}
.summary-blck{
    border-left: 1px solid #E15611;
    margin-bottom:50px;
    margin-top: 20px;
}
.comments-blck-title{
    font-size: 1.4em;
    display: flex;
    align-items: center;
    margin-top: 40px;    
    color:#333;
}
.chat-icon{margin-right:10px;}
.related-blck-permanent{
  margin:0;
  padding:0;
}
.related-blck-permanent li{
    position: relative;
    width: 100%;
    max-width: 300px;
    border-top: 2px solid #E15611;
    border-radius: 20px;
    margin-top: 20px;
    text-align: center;
    background: #FFFFFF;
    background: -webkit-linear-gradient(top, #FFFFFF, #FDF8F5);
    background: -moz-linear-gradient(top, #FFFFFF, #FDF8F5);
    background: linear-gradient(to bottom, #FFFFFF, #FDF8F5);
}
.related-blck-permanent a{text-decoration: none;}
.related-blck-permanent img{margin-top:20px;}
.right-rel-title{
    font-size: calc(1vw + 1vh);
    line-height: calc(1.5vw + 1.5vh);
    text-decoration: underline;
}
.right-summary{
    margin: 10px;
    text-align: left;
    text-decoration:none;
    color:#444;
}
.right-summary-blck{padding-bottom:40px;font-size: calc(6px + 0.5vw + 0.5vh);}
.right-summary-out-icon{position:absolute;bottom:10px;right:20px;width:40px;height:40px;display: flex;align-items: center;justify-content: center;}
.review-credentials{
  width:100%;
  border-top:2px solid #7a7a7a;
  border-bottom:2px solid #7a7a7a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.author-blck{
  width:60%;
  height:100%; 
  display:flex;
}
.byauthor{
  width:50%;
  font-size:calc(4px + 0.8vw + 0.8vh);
  display:flex;
  align-items:center;
  justify-content:center;
}
.trustworthy{
  width:50%;
  height:100%;
  font-size:calc(4px + 1vw + 1vh);
 }
.voting-blck{
  width:30%;
  height:100%;
  display:flex;
}
.like-blck{
  width:50%;
}
.like-img{
  float:right;
  cursor:pointer;
}
.videoBlck{height:calc(20vw + 20vh);}
.video-trailer{margin-bottom:20px;}
.comment-author-blck{font-size:0.8em;display:flex;align-items: center;margin-bottom: 1em;color:#7a7a7a;}
.author-comment-sign{
  width:50px;
  height:15px;
  font-size:0.8em;
  background-color:cadetblue;
  color:#ffffff;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-right:3px;
}
@media (min-width: 768px) { 
  .main-body{
    display:flex;
  }
  #main-content-list{
    width:75%;
    padding: calc(2px + 1vw + 1vh);
  }
  .related-column{
    display:block;
  }
}
.comment-input{
  width:100%;
  height:200px;
  border:1px solid #7a7a7a;
  background-color:#ffffff;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
  margin-top:50px;
  font-size:20px;
}
.comment-input-btn{
  margin-top:5px;
  width:100%;
  height:60px;
  font-size:calc(10px + 1vh + 1vw);
  background-color:#7a7a7a;
  color:#f9f9f9;
  cursor:pointer;
}
</style>
